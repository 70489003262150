@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css');

$default_color: #93298E;

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

header img {
  display: block;
  width: 100px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.landing-image {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 350px;
  background-image: url('../img/stock_2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.landing-image::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #000, #6b1766);
  opacity: 0.4;
  transition-duration: 1s;
  animation: landing-animation 10s infinite;
}

@keyframes landing-animation {
  0%   {
    transform: rotate3d(1, 1, 1, 0deg);
  }
  10% {
    transform: rotate3d(1, 1, 1, 0deg);
  }
  20% {
    transform: rotate3d(1, 1, 1, 45deg);
  }
  30%  {
    transform: rotateX(45deg);
  }
  50%  {
    transform: rotate(45deg);
  }
  60% {
    transform: rotateZ(45deg);
  }
  80% {
    transform: rotate(90deg);
  }
  90% {
    transform: rotate3d(1, 1, 1, -45deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 0deg);
  }
}

.landing-image h1 {
  font-size: 36px;
  text-align: center;
  padding-top: 100px;
  color: #fff;
  position: relative;
  z-index: 2;
  font-weight: 700;
}

@media (max-width: 576px) {
  .landing-image h1 {
    font-size: 28px;
  }
}

.awards {
  .award1 {
    display: block;
    background-image: url(../img/award1.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 55px;
    background-position: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .award2 {
    display: block;
    background-image: url(../img/award2.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 55px;
    background-position: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .award3 {
    display: block;
    background-image: url(../img/award3.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 55px;
    background-position: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }

}


.services {
  margin-top: 75px;

  span {
    position: relative;
    top: 6px;
    margin-right: 10px;
    color: $default_color;
  }

  p {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

footer {
  padding-top: 75px;
  padding-bottom: 25px;
  background-image: linear-gradient(to right, #000, #6b1766);
  margin-top: 100px;

  p {
    font-size: 12px;
    line-height: 20px;
    color: #fff;
  }
  h6 {
    color: #fff;
    margin-bottom: 24px;
  }
}

.small-footer-logo {
  position: relative;
  top: -5px;
}

.small-footer-logo img {
  display: block;
  width: 65px;
  float: right;
}

.loader {
  display: block;
  position: fixed;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
}

.loader .loader-body {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.loader .loader-body img {
  display: block;
  width: 150px;
  margin: auto;
  animation: loader-animation 1.5s;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@keyframes loader-animation {
  0%   {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

.loader.loaded {
  display: none;
}